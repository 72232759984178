import React from "react";
import "./style.css";
import {LoginContent} from "../../components/Content/LoginContent";
import {Header} from "../../components/Header";
import {BottomNav} from "../../components/BottomNav";

export const QrCode = () => {
  
  return (
    <div className="gp-screen">
      <Header headClassname='head-bg'/>
      
        <div className='content-qr animate-fadeIn'>
          <div className="content-qr-wrapper">
            <div className="frame">
              <div className="title">
                <div className="div">
                  <div className="frame-2">
                    <div className="div-wrapper"><p className="text-wrapper">Đưa mã này cho nhân viên quầy để tích
                      điểm</p>
                    </div>
                    <img className="img" src="/img/qrcode/frame-1171276207.svg"/>
                  </div>
                  <div className="frame-3">
                    <img className="combined-shape" src="/img/qrcode/combined-shape.svg"/>
                    <div className="frame-4">
                      <div className="frame-5"></div>
                      <img className="group" src="/img/qrcode/group-4.png"/>
                    </div>
                  </div>
                  <div className="frame-6">
                    <button className="button">
                      <img className="img-2" src="/img/qrcode/download-1.svg"/>
                      <div className="text-wrapper-2">Tải ảnh</div>
                    </button>
                    <button className="button">
                      <img className="img-2" src="/img/qrcode/share-1.svg"/>
                      <div className="text-wrapper-2">Chia sẻ</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img className="bg" src="/img/qrcode/bg.png"/>
          </div>
      </div>
      
      <BottomNav/>
    </div>
  );
};