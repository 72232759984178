import React from "react";
import "./style.css";

const Popup = ({children}) => {
	return (
		<div className="popup-container">
			<div className="popup-wrapper">
				{children}
			</div>
		</div>
	);
};

export default Popup;
