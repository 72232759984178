import React from "react";
import { BottomNav } from "../../components/BottomNav";
import "./style.css";
import {OverlapBg} from "../../components/OverlapBg";
import {Header} from "../../components/Header";
import {HomeContent} from "../../components/Content/HomeContent";
import {getLocalStorageData} from "../../utils/utils";
import {useLocation} from "react-router-dom";

export const Home = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(queryParams)
  const userData = getLocalStorageData('userData') || {};
  const phoneNumber = localStorage.getItem('phoneNumber') || params?.phone || "";
  console.log(localStorage.getItem('phoneNumber'), params, userData)
  
  return (
    <div className="gp-screen">
      <OverlapBg />
      <Header />
      <HomeContent phoneNumber={phoneNumber} userData={userData?.data}/>
      <BottomNav />
    </div>
  );
};
