import React from "react";
import "./style.css";
import HistoryItem from "../../HistoryItem/HistoryItem";
import {HISTORY_DATA_1, HISTORY_DATA_2} from "../../../dummy";

export const HistoryContent = () => {

  return (
    <div className="element-lich-su history-content animate-fadeIn">
      <div className='history-content-wrapper'>
        <div className="filter ">
          <div className="filter-item filter-active">
            <p>Tất cả</p>
          </div>
          <div className="filter-item">
            <p>Cộng điểm</p>
          </div>
          <div className="filter-item">
            <p>Sử dụng điểm</p>
          </div>
          <div className="filter-item">
            <p>Sử dụng điểm</p>
          </div>
        </div>
        <div className="title">
          <div className="text-wrapper-5">Mới nhất</div>
        </div>
        <div className='history-list'>
          {HISTORY_DATA_1.map((item, index) => (
            <HistoryItem key={index} {...item} />
          ) )}
        </div>
        <div className="title">
          <div className="text-wrapper-5">Tháng 7/ 2024</div>
        </div>
        
        <div className='history-list'>
          {HISTORY_DATA_2.map((item, index) => (
            <HistoryItem key={index} {...item} />
          ) )}
        </div>
        
      </div>
    </div>
  );
};