import React from "react";
import {BottomNav} from "../../components/BottomNav";
import "./style.css";
import {Header} from "../../components/Header";
import {HistoryBrandContent} from "../../components/Content/HistoryBrandContent";

export const HistoryBrand = () => {
  
  return (
    <div className="gp-screen">
      <Header headClassname='head-bg'/>
      <HistoryBrandContent/>
      <BottomNav/>
    </div>
  );
};
