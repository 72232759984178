import React from "react";
import "./style.css";

export const OverlapBg = () => {

  return (
    <div className="overlap-bg">
      <div className="bg">
        <div className="overlap-group">
          <div className="mask-group"><img className="intersect" src="/img/intersect.svg" alt='mask_group'/></div>
          <div className="intersect-wrapper"><img className="intersect-wrapper-img" src="/img/intersect-1.svg" alt='intersect'/></div>
        </div>
      </div>
    </div>
  );
};

OverlapBg.propTypes = {};
