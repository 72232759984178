import React from 'react';
import './style.css';
import {RANK} from "../../constants/appConst";

const milestones = [
	{ id: 1, label: 'Start', position: 0 },
	{ id: 2, label: 'Milestone 1', position: 25 },
	{ id: 3, label: 'Milestone 2', position: 50 },
	{ id: 4, label: 'Milestone 3', position: 75 },
	{ id: 5, label: 'End', position: 100 },
];

const ProgressMilestone = ({ progress, loyaltyRank }) => {
	
	console.log(progress, loyaltyRank)
	
	return (
		<div className="vefs-milestone-wrapper">
			<div className="milestone-container">
				<div className="label-container">
					<div className="milestones milestone__0__label">
						<div style={loyaltyRank === RANK.BRONZE.code ? {fontWeight: 'bold', fontSize: '14px', top: '-4px'} : null}
						     className="label colored">Đồng
						</div>
					</div>
					<div className="milestones milestone__33__label">
						<div style={loyaltyRank === RANK.SILVER.code ? {fontWeight: 'bold', fontSize: '14px', top: '-4px'}: null} className="label colored">Bạc</div>
					</div>
					<div className="milestones milestone__66__label">
						<div style={loyaltyRank === RANK.GOLD.code ? {fontWeight: 'bold', fontSize: '14px', top: '-4px'} : null}
						     className="label colored">Vàng
						</div>
					</div>
					<div className="milestones milestone__100_diamond"
					     style={loyaltyRank === RANK.DIAMOND.code ? {width: '24px', height: '20px', top: '-5px'} : null}>
						<img style={loyaltyRank === RANK.DIAMOND.code ? {width: '24px', height: '20px'}:null} src="/img/tv/kimcuong-icon.png" alt="progress-diamond"/>
					</div>
				</div>
				<div className="chart-container">
					<div className="line-container">
						<div className="pr-line"></div>
						<div className="pr-line left" style={{width: `${progress}%`}}></div>
					</div>
					<div className="dot-container">
						<div className="milestones milestone__0">
							{
								(progress >= 0 && progress < 20) ? (
									<img className='point-tick' src='/img/point-o.png' alt='point-tick'/>
								) : (
									<img className='point-tick' src='/img/point-tick.png' alt='point-tick'/>
								)
							}
						</div>
						<div className="milestones milestone__33">
							{
								(progress >= 0 && progress < 20) ? (
									<img className='point-tick' src='/img/point.png' alt='point-tick'/>
								) : (progress >= 20 && progress < 60) ? (
									<img className='point-tick' src='/img/point-o.png' alt='point-tick'/>
								) : (
									<img className='point-tick' src='/img/point-tick.png' alt='point-tick'/>
								)
							}
						</div>
						<div className="milestones milestone__66">
							{
								(progress >= 0 && progress < 60) ? (
									<img className='point-tick' src='/img/point.png' alt='point-tick'/>
								) : (progress >= 60 && progress < 100) ? (
									<img className='point-tick' src='/img/point-o.png' alt='point-tick'/>
								) : (
									<img className='point-tick' src='/img/point-tick.png' alt='point-tick'/>
								)
							}
						</div>
						<div className="milestones milestone__100-point">
							
							{
								(progress >= 0 && progress < 100) ? (
									<img className='point-tick' src='/img/point.png' alt='point-tick'/>
								) : (
									<img className='point-tick' src='/img/point-o.png' alt='point-tick'/>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	
	);
};

export default ProgressMilestone;