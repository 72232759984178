import React from "react";
import "./style.css";
import {MySwiper} from "../MySwiper";


const DATA = [
  {
    imgSrc: '/img/feature-prog/banner-1.png',
  },
  {
    imgSrc: '/img/feature-prog/banner-2.png',
  },
  {
    imgSrc: '/img/feature-prog/banner-3.png',
  },
  {
    imgSrc: '/img/feature-prog/xem-them.png',
  }
]

const FeatureProgram = () => {
  const renderSwiperItem = ({
    imgSrc
  }) => {
    return (
      <img src={imgSrc} alt="alt"/>
    );
  }
  
  return (
    <div className="section mt-4">
      <div className='section-title'>
        <div className="section-title-label">Chương trình nổi bật</div>
        <img className="section-title-icon" src="/img/group-5.png" alt='group-5'/>
      </div>
      <div className='section-body'>
        <MySwiper
          itemClass="swiper-slide-fp"
          slidesPerView={'auto'}
          spaceBetween={10}
          data={DATA}
          renderSwiperItem={renderSwiperItem}
        />
      </div>
    </div>
  );
};

export default FeatureProgram;