import React from "react";
import "./style.css";

const Benefit = ({userData, rankData}) => {
	
	const {benefitTitle, benefitCount} = rankData || {};
	
	console.log(rankData)
	
	return (
		<div className="benefit">
			<div className="benefit-title">{benefitTitle}</div>
			<div className='benefit-wrapper'>
				{
					benefitCount >= 1 && (
						<div className='benefit-item'>
							<div className="image-wrapper"><img className="/img-ct" src="/img/image-20.png"/></div>
							<p className="u-ti-n-tr-i-nghi-m-t">
								<span className="span">Ưu tiên<br/></span>
								<span className="text-wrapper-3">thanh toán bằng điểm tích luỹ</span>
							</p>
						</div>
					)
				}
				{ benefitCount >=2 && (
						<div className='benefit-item'>
							<img className="image" src="/img/image-18.png"/>
							<p className="u-i-khi-mua-v-futa">
								<span className="span">Tặng vé<br/></span> <span
								className="text-wrapper-2">sử dụng dịch vụ<br/>FUTA City Bus</span>
							</p>
						</div>
					)
				}
				{
					benefitCount >=3 && (
						<div className='benefit-item'>
							<div className="group">
								<div className="overlap-group">
									<img className="mask-group" src="/img/benefit-1.png" alt='benefit-1'/>
								</div>
							</div>
							<p className="t-ch-i-m-khi-s-d">
								<span className="span">Nhận quà<br/></span> <span
								className="text-wrapper-2">miễn phí ở các trạm dừng Phúc Lộc</span>
							</p>
						</div>
					)
				}
			
			
			</div>
		</div>
	);
};

export default Benefit;
