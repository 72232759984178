// src/components/Toast/Toast.jsx
import React, { useState, useEffect } from 'react';
import './style.css';

const Toast = ({ message, duration = 3000, onClose }) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			onClose();
		}, duration);
		
		return () => clearTimeout(timer);
	}, [duration, onClose]);
	
	return (
		<div className="toast text-center w-[90%]">
			{message}
		</div>
	);
};

export default Toast;