import React from "react";
import "./style.css";
import classnames from 'classnames';
import {useLocation, useNavigate} from "react-router-dom";
import {HEADER_TITLE} from "../../dummy";
import {PAGE} from "../../constants/appConst";

export const Header = ({headClassname}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {pathname} = location || {};

  const headerClassname = classnames('head', headClassname)
  const onBack = () => {
    if (pathname === PAGE.HOME) {
      navigate(PAGE.LOGIN)
    } else navigate(-1)
  }
  return (
    <div className={headerClassname}>
      <div className='layer' onClick={onBack}>
        <img src="/img/layer-3-2.svg" alt='close_customer'/>
      </div>
      <div className="loyalty-program">{HEADER_TITLE[pathname]}</div>
      <div className="group">
        <div className="header-div">
          <img className="layer-2" src="/img/layer-3-3.svg" alt='notify'/>
          <div className="thong-bao">
            <div className="text-wrapper">5</div>
          </div>
        </div>
      </div>
    </div>
  );
};