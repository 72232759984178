import React, {useEffect, useMemo, useRef, useState} from "react";
import "./style.css";
import {RANKINGS} from "../../../dummy";
import {MySwiper} from "../../MySwiper";
import {Link} from "react-router-dom";
import {PAGE, RANK} from "../../../constants/appConst";
import ProgressMilestone from "../../ProgressMilestone/ProgressMilestone";
import {formatNumberWithDot} from "../../../utils/utils";

export const RankingContent = ({userData, phoneNumber}) => {
  const { loyaltyRank, loyaltyStar } = userData || {}
  const [activeIndex, setActiveIndex] = useState(RANKINGS.findIndex(item => item.code === (loyaltyRank || 'BRONZE')))
  const [animate, setAnimate] = useState(true)
  const timeout = useRef()
  const activeItem = RANKINGS[activeIndex]
  
  const progressRank = useMemo(() => Math.round((loyaltyStar||0) / 50000 * 100), [loyaltyStar])
  
  const renderSwiperItem = ({imgSrc, bgSrc}) => {
    return(
      <div className='rank-item'>
        <img className='rank-item-img' src={imgSrc} alt="imgSrc"/>
      </div>
    );
  }
  
  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex)
    setAnimate(true)
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setAnimate(false)
    }, 1000)
  }
  
  const rankData = RANK[loyaltyRank] || RANK.BRONZE
  
  return (
    <div className="element-ranking ranking-content animate-fadeIn">
      <div className='ranking-content-wrapper'>
          <div className="rank-info">
            <div className={`rank-item-bg${animate ? ' rank-bg-animate' : ''}`}>
              <img className={`${animate ? ' fadeInAnimation' : ''}`}
                   src={activeItem?.bgSrc} alt="bgSrc"/>
            </div>
            <div className="name-point">
              <div className="name">{phoneNumber}</div>
              <div className="point">
                <div className="point-text">{formatNumberWithDot(loyaltyStar || 0)} điểm</div>
              </div>
            </div>
            <div className="marquee-container w-[200px] overflow-hidden">
              <div className="marquee text-white text-[12px]">
                {rankData.code === RANK.DIAMOND.code ? "Chúc mừng  bạn đã đạt hạng thành viên cao nhất!" : "Chỉ cần cố gắng chút nữa, bạn sẽ thăng hạng!"}
              </div>
            </div>
            <div className='rank-bar'>
              <ProgressMilestone loyaltyRank={rankData?.code} progress={rankData?.progress} />
            </div>
            <MySwiper
              data={RANKINGS}
              itemClass='swiper-item-ranking'
              centeredSlides={true}
              slidesPerView={'auto'}
              spaceBetween={25}
              initialSlide={activeIndex}
              renderSwiperItem={renderSwiperItem}
              onSlideChange={onSlideChange}
            />
          </div>
          <div className="quyen-loi">
            <div className='polygon'>
              <img className="polygon-img" src="/img/ranking/polygon-1.svg" alt='polygon'/>
            </div>
            <p className="p">{activeItem?.title}</p>
            <div className='rk-benefit'>
              {
                activeItem?.benefitCount >= 1 && (
                  <div className='rk-benefit-item'>
                    <div className="overlap">
                      <img className="image-2" src="/img/ranking/image-20.png" alt='image-2'/>
                    </div>
                    <p className="u-ti-n-tr-i-nghi-m-t">
                      <span className="span">Ưu tiên<br/></span>
                      <span className="text-wrapper-4">thanh toán bằng điểm tích luỹ</span>
                    </p>
                  </div>
                )
              }
              {activeItem?.benefitCount >= 2 && (
                <div className='rk-benefit-item'>
                  <img className="image" src="/img/image-18.png"/>
                  <p className="u-i-khi-mua-v-futa">
                    <span className="span">Tặng vé<br/></span>
                    <span className="text-wrapper-3">sử dụng dịch vụ<br/>FUTA City Bus</span>
                  </p>
                </div>
              )}
              {activeItem?.benefitCount >= 3 && (
                <div className='rk-benefit-item'>
                  <div className="group">
                    <div className="overlap-group-4">
                      <img className="mask-group" src="/img/benefit-1.png" alt='mask-group'/>
                    </div>
                  </div>
                  <p className="t-ch-i-m-khi-s-d">
                    <span className="span">Nhận quà<br/></span> <span
                    className="text-wrapper-3">miễn phí ở các trạm dừng Phúc Lộc</span>
                  </p>
                </div>
                )}
            </div>
            {activeItem.code === 'DIAMOND' && (
              <div className='benefit-add'>
                <img className="benefit-add-icon" src="/img/add_to.png" alt="add_to"/>
                <p className='benefit-add-text'><b>Quyền lợi của FUTA City Bus:</b> Huỷ vé, đổi vé miễn phí</p>
              </div>
            )}
          
          </div>
          <div className="lich-su-tich-diem">
            <Link to={PAGE.HISTORY_BRAND}>
              <img src="/img/ranking/banner_history_point_brand.png" alt='banner_history_point_brand'/>
            </Link>
          </div>
      </div>
    </div>
  );
};

