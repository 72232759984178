import React from "react";
import "./style.css";

import {Swiper, SwiperSlide} from "swiper/react";
import PropTypes from "prop-types";
// Import Swiper styles
import "swiper/css";

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

export const MySwiper = ({
   data,
	 renderSwiperItem,
	 slidesPerView,
	 centeredSlides,
	 spaceBetween,
   onSlideChange,
   initialSlide,
	 itemClass
	}) => {
	
	console.log({
		data,
		renderSwiperItem,
		slidesPerView,
		centeredSlides,
		spaceBetween,
		onSlideChange,
		initialSlide,
		itemClass
	})
	return (
		<Swiper
			className="my-swiper"
			centeredSlides={centeredSlides}
			slidesPerView={slidesPerView}
			spaceBetween={spaceBetween}
			onSlideChange={onSlideChange}
			initialSlide={initialSlide || 0}
			modules={[EffectFade]}
		>
			{
				(data || []).map((item, index) => (
					<SwiperSlide className={itemClass || ''} key={index}>{renderSwiperItem(item)}</SwiperSlide>
				))
			}
		</Swiper>
	);
};

MySwiper.propTypes = {
	data: PropTypes.array.isRequired,
	renderSwiperItem: PropTypes.func.isRequired,
};
