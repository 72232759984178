import React from "react";
import "./style.css";
import {Link} from "react-router-dom";
import {PAGE, RANK} from "../../../constants/appConst";

const CompanionContent_2 = ({setStep, userData}) => {
  const {loyaltyRank} = userData || {}

  return (
    <div className='companion-content slide-in'>
      <div className='companion-content-wrapper'>
        <div className="frame">
          <div className="overlap-group">
            <div className="LOGO-FUTA-GROUP">
              <img src="/img/companion/logo-futa-group-2023-01-51a8be4af1-1.png" alt='123'/>
            </div>
            <p className="c-m-n-b-n-v-ng-h-nh mt-[20px]">
              <p className="title-1">Chúc mừng bạn!</p>
              <p className="desc-1">Từ số điểm tích luỹ được trong năm 2023, bạn chính thức là:</p>
              <p className="desc-2 font-bold text-[#EF5222]">{RANK[loyaltyRank]?.memberRank}</p>
              <p className='desc-1'>của FUTA Group</p>
            </p>
            <div className='celebrate'>
              <img src="/img/companion/celebrate.gif" alt='celebrate'/>
            </div>
            <p className='desc-1 bottom-desc'>Cảm ơn vì sự đồng hành của bạn, đây là động lực rất lớn để FUTA Group luôn cống hiến, luôn đóng góp hết sức mình cho cộng đồng. Bạn hãy cùng chúng tôi tiếp tục phát triển và xây dựng đất nước nhé.</p>
          </div>
        </div>
        <Link to={PAGE.HOME}>
          <div className="btn-ani">
            <div className="text rectangle">Về trang chủ</div>
          </div>
        </Link>
      </div>
    </div>
  
  );
};

export default CompanionContent_2;

