import React from "react";
import {BottomNav} from "../../components/BottomNav";
import "./style.css";
import {Header} from "../../components/Header";
import {CompanionContent} from "../../components/Content/CompanionContent";
import CompanionContent_1 from "../../components/Content/CompanionContent/CompanionContent_1";
import CompanionContent_2 from "../../components/Content/CompanionContent/CompanionContent_2";
import {getLocalStorageData} from "../../utils/utils";

export const Companion = () => {
  const [step, setStep] = React.useState(0);
  const userData = getLocalStorageData('userData') || {};
  
  console.log(userData)
  return (
    <div className="gp-screen">
      <Header headClassname='head-bg' />
      {step===0 && <CompanionContent setStep={setStep} userData={userData?.data||{}}/>}
      {step===1 && <CompanionContent_1 setStep={setStep} userData={userData?.data||{}}/>}
      {step===2 && <CompanionContent_2 setStep={setStep} userData={userData?.data||{}}/>}
      <BottomNav />
    </div>
  );
};
