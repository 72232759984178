import React from "react";
import "./style.css";

const HistoryItem = ({
  iconSrc, name, desc, timeString, point
                     }) => {
  
  return (
    <div className="history-item">
      <div className="history-item-icon">
        <img src={iconSrc} alt='history-item'/>
      </div>
      <p className="history-item-info">
        <span className="span">{name}<br/></span>
        <span className="text-wrapper-6">{desc}<br/></span>
        <span className="text-wrapper-7">{timeString}</span>
      </p>
      <div className="history-item-point">{point}</div>
    </div>
  );
};

export default HistoryItem;