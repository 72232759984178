import React, {useEffect, useState} from "react";
import "./style.css";
import {PAGE} from "../../../constants/appConst";
import {Link, useNavigate} from "react-router-dom";
import {normalizeVietnamesePhoneNumber} from "../../../utils/utils";
import {fetchData} from "../../../api/api";
import {clearData} from "../../../functions/functions";
import Toast from "../../Toast/Toast";

export const LoginContent = ({setStep}) => {
  const navigate = useNavigate();
  const videoRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  
  const [toastMessage, setToastMessage] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [state, setState] = useState({
    style: {}, animation: false
  });
  
  const {style, animation} = state || {};
  
  useEffect(() => {
    clearData();
    const video = videoRef.current
    const top = 185;
    // Simulate a delay before starting the animation
    setTimeout(() => {
      setState({
        style: {
          transform: `translate(0px, -${top - 60}px)`, // Final position
          transition: 'transform .5s ease-in-out', // Smooth transition
        }, animation: true
      });
    }, 1000); // Delay of 500ms before the animation starts
  }, [videoRef]);
  
  const onInputChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
  }
  
  const onCheckPhoneContinue = async () => {
    const phone = normalizeVietnamesePhoneNumber(phoneNumber)
    // validate phone number in vietnam
    const regex = /^(?:\+84|84|0)(3[2-9]|5[6|8|9]|7[0|6-9]|8[1-6|8|9]|9[0-9])[0-9]{7}$/;
    if(regex.test(phone)) {
      localStorage.setItem('phoneNumber', phone);
      navigate(`${PAGE.HOME}?phone=${phone}`);
    } else {
      setToastMessage("Số điện thoại không hợp lệ")
    }
  }
  
  
  return (
    <div className="element-splsh">
      <div className="element-splsh-wrapper">
        {animation && <div className="overlap slide-in-top">
          <div className="overlap-group">
            <div className="logo-futa-splash">
              <div className="square"></div>
            </div>
          </div>
        
        </div>}
        {
          animation && <p className="ch-ng-tr-nh-kh-ch-h animate-fadeIn">
            <span className="text-wrapper-sp">Chương trình Khách hàng thân thiết FUTA Group<br/></span>
            <span className="text-wrapper-2"
            >FUTAPoint là Chương trình Khách hàng thân thiết độc quyền dành cho các&nbsp;&nbsp;khách hàng cao cấp của
            Tập đoàn FUTA Group, tập hợp các ưu đãi tài chính&nbsp;&nbsp;hấp dẫn, đặc quyền phi tài chính đẳng
            cấp.</span
            >
          </p>
        }
        {
          animation && <div className="login slide-in-bottom">
            <p className="div">Truy cập bằng số điện thoại</p>
            <div className="group">
              <div className="div-wrapper">
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  
                  ref={phoneRef}
                  className="text-wrapper-3"
                  placeholder="Nhập số điện thoại"
                  value={phoneNumber}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <button className={`btn${!phoneNumber?' disabled':''}`} onClick={onCheckPhoneContinue}>
              <div className="text">Tiếp tục</div>
            </button>
            <p className="b-ng-vi-c-nh-n-ti-p">
              <span className="text-wrapper-4">Bằng việc nhấn “Tiếp tục” đồng nghĩa là bạn đã đồng ý với</span>
              <span className="text-wrapper-5">&nbsp;</span>
              <span className="text-wrapper-6">Điều khoản &amp; Điều kiện, chính sách bảo vệ và xử lý dữ liệu</span>
            </p>
          </div>
        }
        <video className='video-login' ref={videoRef}
               style={style}
               src="/video/logo-futa-ani.mp4" autoPlay muted playsInline></video>
      </div>
      {toastMessage && <Toast message={toastMessage} onClose={() => setToastMessage('')} />}
    </div>
  
  );
};

