import React from "react";
import "./style.css";
import {PAGE} from "../../../constants/appConst";
import {Link} from "react-router-dom";

export const CompanionContent = ({setStep, userData}) => {
  
  if(!userData?.phoneNumber) {
    return (
      <div className='companion-content slide-in'>
        <div className='companion-content-wrapper'>
          <div className="frame">
            <div className="overlap-group">
              <div className="LOGO-FUTA-GROUP">
                <img src="/img/companion/logo-futa-group-2023-01-51a8be4af1-1.png" alt='123'/>
              </div>
              <div className='banner'>
                <img className='banner-img' src="/img/companion/banner-5-1.png" alt='banner'/>
              </div>
              <p className="c-m-n-b-n-v-ng-h-nh  mt-[20px]">
                <p className="text-wrapper-cp text-[#EF5222]">Rất tiếc, bạn chưa từng trải nghiệm dịch vụ nào của FUTA Group</p>
                <p className="text-wrapper-2">Còn chần chờ gì nữa mà không truy cập FUTA và sử dụng những dịch vụ của chúng tôi.</p>
                <p className="text-wrapper-2">
                  Chắc chắn bạn sẽ có những hành trình thú vị cùng FUTA City Bus và trải nghiệm tuyệt vời cùng với FUTA Express
                </p>
                <img src="/img/companion/futa-link.png" alt='group-3'/>
              </p>
            </div>
            
            <Link className="btn-ani" to={PAGE.HOME}>
              <img className='btn-ani-img' src="/img/companion/button_ani.gif" alt="button_ani"/>
              <div className="text">Về trang chủ</div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='companion-content slide-in'>
      <div className='companion-content-wrapper'>
        <div className="frame">
          <div className="overlap-group">
            <div className="LOGO-FUTA-GROUP">
              <img src="/img/companion/logo-futa-group-2023-01-51a8be4af1-1.png" alt='123'/>
            </div>
            <img className="banner" src="/img/companion/banner-1-1.png"/>
            <p className="c-m-n-b-n-v-ng-h-nh  mt-[20px]">
              <span className="text-wrapper-cp">Cám ơn bạn vì đã đồng hành cùng FUTA Group!<br/></span>
              <span className="span"><br/></span>
              <span className="text-wrapper-2"
              >Hãy cùng xem lại hành trình trải nghiệm tuyệt vời của bạn cùng chúng tôi tại đây nhé!</span
              >
            </p>
          </div>
          <div className="btn-ani" onClick={() => setStep(1)}>
            <img className='btn-ani-img' src="/img/companion/button_ani.gif" alt="button_ani"/>
            <div className="text">Bắt đầu thôi</div>
          </div>
        </div>
      </div>
    </div>
  
  );
};

