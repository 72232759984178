import React, {useEffect, useMemo, useRef, useState} from "react";
import "./style.css";
import {RANKINGS} from "../../../dummy";
import {MySwiper} from "../../MySwiper";
import {Link} from "react-router-dom";
import {EL_ID, PAGE} from "../../../constants/appConst";
import {formatNumberWithDot} from "../../../utils/utils";

const CompanionContent1 = ({setStep, userData}) => {
  const [milestone, setMilestone] = useState(-1)
  const [ended, setEnded] = useState(false)
  
  const {userLifetime, userFirstOrderDate, loyaltyStar, totalSpending, businessUnit} = userData || {}
  
  const totalSpendingDot = useMemo(() => formatNumberWithDot(totalSpending||0), [totalSpending])
  const loyaltyStarDot = useMemo(() => formatNumberWithDot(loyaltyStar||0), [loyaltyStar])
  
  
  useEffect(() => {
    console.log('ended', ended)
    if (ended) {
      const element = document.getElementById(EL_ID.COMPANION_WRAPPER);
      if (element){
        element.scroll({
          top: element.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [ended]);
  
  const onTimeUpdate = (e) => {
    const video = e?.target;
    const currentTime = video?.currentTime;
    if (currentTime > 1 && currentTime < 2 && milestone === -1) {
      setMilestone(0)
    } else if (currentTime > 5 && currentTime < 6 && milestone === 0) {
      setMilestone(1)
    } else if(currentTime > 12 && currentTime < 13 && milestone === 1) {
      setMilestone(2)
    } else if(currentTime > 13 && milestone === 2) {
      setEnded(true)
    }
  }
  
  const onEnded = () => {
    setEnded(true)
  }
  
  const renderMilestone1 = () => {
    return (
      <div className='milestone1 slide-in'>
        <img className='c2-1' src="/img/companion/calendar.png" alt='milestone1'/>
        {userFirstOrderDate && <div className='date'>{userFirstOrderDate}</div>}
        <div className='text-black'>
          <p className='desc1'>là ngày đầu tiên bạn đến với FUTA Group</p>
          <p
            className='desc2'
            dangerouslySetInnerHTML={{
              __html: `và tổng thời gian bạn đã đồng hành cùng FUTA Group là <b>${userLifetime} ngày</b>`
            }}
          />
        </div>
      </div>
    )
  }
  
  const renderMilestone2 = () => {
    return (
      <div className='milestone2 slide-in'>
        <img className='c2-1' src="/img/companion/star.png" alt='milestone2'/>
        <div className='date'>{totalSpendingDot} đ</div>
        <p className='desc1'>là tổng số tiền bạn đã gửi gắm ở FUTA Group</p>
        <div className='text-black'>
          <p
            className='desc2'
            dangerouslySetInnerHTML={{
              __html: `Những dịch vụ của FUTA Group mà bạn đã trải nghiệm là: <b>${businessUnit}</b>`
            }}
          />
        </div>
      </div>
    )
  }
  
  const renderMilestone3 = () => {
    return (
      <div className='milestone3 slide-in'>
        <img className='c2-1' src="/img/companion/trophy.png" alt='milestone3'/>
        <div className='date'>{loyaltyStarDot} điểm</div>
        <div className='text-black'>
          <p
            className='desc2'
            dangerouslySetInnerHTML={{
              __html: `là số điểm bạn đã tích lũy được trong quá trình bạn sử dụng dịch vụ của chúng tôi`
            }}
          />
        </div>
      </div>
    )
  }
  
  return (
    <div className='companion-content slide-in'>
      <div className='companion-content-wrapper' id={EL_ID.COMPANION_WRAPPER}>
        <div className="frame">
          <div className="overlap-group">
            <div className="LOGO-FUTA-GROUP">
              <img src="/img/companion/logo-futa-group-2023-01-51a8be4af1-1.png" alt='123'/>
            </div>
            <div className='milestone'>
              {/*{renderMilestone1()}*/}
              {milestone === 0 && renderMilestone1()}
              {milestone === 1 && renderMilestone2()}
              {milestone === 2 && renderMilestone3()}
            </div>
            <div className='video-bus'>
              <video className='video-bus-video' src="/video/bus_run.mp4"
                     autoPlay
                     onTimeUpdate={onTimeUpdate}
                     onEnded={onEnded}
                     playsInline
                     muted
              ></video>
            </div>
          </div>
        </div>
        {ended &&
          <div className="btn-ani" onClick={() => setStep(2)}>
            <img className='btn-ani-img' src="/img/companion/button_ani.gif" alt="button_ani"/>
            <div className="text">Xem tiếp nào</div>
          </div>
        }
      </div>
    </div>
  
  );
};

export default CompanionContent1;

