import {PAGE} from "../constants/appConst";


export const HISTORY_DATA_1 = [
	{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'Hoàn thành chuyến đi',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	},
	{
		iconSrc:"/img/history/image-16-7.png",
		name:'Ví GooPay',
		desc:'Thanh toán bằng điểm thưởng',
		timeString:'17:28 - 05/08/2024',
		point:"-120 điểm"
	},
	{
		iconSrc:"/img/history/image-16-9.png",
		name:'Mua cafe Highlands',
		desc:'Hoàn tất thanh toán',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	},{
		iconSrc:"/img/history/image-16-11.png",
		name:'Mua hàng tại Uniqlo',
		desc:'Hoàn tất thanh toán',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	}
];
export const HISTORY_DATA_2 = [
	{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'Hoàn thành chuyến đi',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	},
	{
		iconSrc:"/img/history/image-16-7.png",
		name:'Ví GooPay',
		desc:'Thanh toán bằng điểm thưởng',
		timeString:'17:28 - 05/08/2024',
		point:"-120 điểm"
	},
	{
		iconSrc:"/img/history/image-16-9.png",
		name:'Mua cafe Highlands',
		desc:'Hoàn tất thanh toán',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	},{
		iconSrc:"/img/history/image-16-11.png",
		name:'Mua hàng tại Uniqlo',
		desc:'Hoàn tất thanh toán',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	},{
		iconSrc:"/img/history/image-16-5.png",
		name:'Mua hàng tại Starbucks',
		desc:'Đổi voucher 30k',
		timeString:'17:28 - 05/08/2024',
		point:"-30 điểm"
	},{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'Hoàn thành chuyến đi',
		timeString:'17:28 - 05/08/2024',
		point:"+70 điểm"
	}
];
export const RANKINGS = [
	{
		code: 'BRONZE',
		title: 'Quyền lợi thành viên Đồng',
		imgSrc:"/img/ranking/cards/bronze.png",
		bgSrc: "/img/ranking/cards/bg-bronze.png",
		benefitCount: 1,
		order: 0
	},
	{
		code: 'SILVER',
		title: 'Quyền lợi thành viên Bạc',
		imgSrc:"/img/ranking/cards/silver.png",
		bgSrc: "/img/ranking/cards/bg-silver.png",
		benefitCount: 2,
		order: 1
	},
	{
		code: 'GOLD',
		title: 'Quyền lợi thành viên Vàng',
		imgSrc:"/img/ranking/cards/gold.png",
		bgSrc: "/img/ranking/cards/bg-gold.png",
		benefitCount: 3,
		order: 2
	},
	{
		code: 'DIAMOND',
		title: 'Quyền lợi thành viên Kim Cương',
		imgSrc:"/img/ranking/cards/diamond.png",
		bgSrc: "/img/ranking/cards/bg-diamond.png",
		benefitCount: 3,
		order: 3
	}
]
export const HEADER_TITLE = {
	[PAGE.HOME]: 'Khách hàng thân thiết',
	[PAGE.HISTORY]: 'Lịch sử',
	[PAGE.RANKING]: 'Hạng',
	[PAGE.HISTORY_BRAND]: 'Lịch sử thương hiệu',
	[PAGE.COMPANION]: 'Đồng hành cùng FUTA Group',
	[PAGE.QRCODE]: 'Mã tích điểm',
}


export const HISTORY_BRAND_DATA_1 = [
	{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'15 lần',
		point:"+70 điểm"
	},
	{
		iconSrc:"/img/history/image-16-7.png",
		name:'Ví GooPay',
		desc:'23 lần',
		point:"+120 điểm"
	},
	{
		iconSrc:"/img/history/image-16-9.png",
		name:'Mua cafe Highlands',
		desc:'5 lần',
		point:"+92 điểm"
	},{
		iconSrc:"/img/history/image-16-11.png",
		name:'Mua hàng tại Uniqlo',
		desc:'3 lần',
		point:"+10 điểm"
	}
];
export const HISTORY_BRAND_DATA_2 = [
	{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'10 lần',
		timeString:'17:28 - 05/08/2024',
		point:"+60 điểm"
	},
	{
		iconSrc:"/img/history/image-16-7.png",
		name:'Ví GooPay',
		desc:'15 lần',
		point:"+120 điểm"
	},
	{
		iconSrc:"/img/history/image-16-9.png",
		name:'Mua cafe Highlands',
		desc:'15 lần',
		point:"+70 điểm"
	},{
		iconSrc:"/img/history/image-16-11.png",
		name:'Mua hàng tại Uniqlo',
		desc:'15 lần',
		point:"+70 điểm"
	},{
		iconSrc:"/img/history/image-16-5.png",
		name:'Mua hàng tại Starbucks',
		desc:'15 lần',
		point:"+90 điểm"
	},{
		iconSrc:"/img/history/image-16.png",
		name:'FUTA City Bus',
		desc:'2 lần',
		timeString:'17:28 - 05/08/2024',
		point:"+20 điểm"
	}
];