import React from "react";
import {BottomNav} from "../../components/BottomNav";
import "./style.css";
import {Header} from "../../components/Header";
import {RankingContent} from "../../components/Content/RankingContent";
import {getLocalStorageData} from "../../utils/utils";

export const Ranking = () => {
  
  const userData = getLocalStorageData('userData') || {};
  const phoneNumber = localStorage.getItem('phoneNumber') || "";
  
  return (
    <div className="gp-screen">
      <Header headClassname='head-bg' />
      <RankingContent phoneNumber={phoneNumber} userData={userData?.data}/>
      <BottomNav />
    </div>
  );
};
