export function normalizeVietnamesePhoneNumber(phoneNumber) {
	// Remove spaces, hyphens, and other non-numeric characters
	phoneNumber = phoneNumber.replace(/\D/g, '');
	
	// Check if the number starts with '+84' or '84' and strip it
	if (phoneNumber.startsWith('84')) {
		phoneNumber = '0' + phoneNumber.slice(2);
	}
	
	// Handle case where number starts with '0' after stripping '+84'
	if (phoneNumber.startsWith('00')) {
		phoneNumber = phoneNumber.slice(1);
	}
	
	return phoneNumber;
}


export function getLocalStorageData(key) {
	const data = localStorage.getItem(key);
	if (data) {
		try {
			return JSON.parse(data);
		} catch (error) {
			console.error('Error parsing userData from local storage:', error);
			return null;
		}
	}
	return null;
}

export function formatNumberWithDot(number) {
	return new Intl.NumberFormat('de-DE').format(number);
}