import React from "react";
import "./style.css";
import {LoginContent} from "../../components/Content/LoginContent";

export const Login = () => {
  
  return (
    <div className="gp-screen">
      <LoginContent />
    </div>
  );
};