import React from "react";
import "./style.css";
import HistoryItem from "../../HistoryItem/HistoryItem";
import {HISTORY_BRAND_DATA_1, HISTORY_BRAND_DATA_2} from "../../../dummy";

export const HistoryBrandContent = () => {
  
  return (
    <div className="element-lich-su-thuong-hieu history-content animate-fadeIn">
      <div className='history-content-wrapper'>
        <div className="title">
          <div className="text-wrapper-5">Tháng 8/ 2024</div>
        </div>
        <div className="title sub-title">
          <div className="text-wrapper-5">Tổng 75 lượt sử dụng</div>
          <div className="history-item-point">+ 1.050 điểm</div>
        </div>
        <div className='history-list'>
          {HISTORY_BRAND_DATA_1.map((item, index) => (
            <HistoryItem key={index} {...item} />
          ))}
        </div>
        <div className="title">
          <div className="text-wrapper-5">Tháng 7/ 2024</div>
        </div>
        <div className="title sub-title">
          <div className="text-wrapper-5">Tổng 100 lượt sử dụng</div>
          <div className="history-item-point">+ 1.500 điểm</div>
        </div>
        
        <div className='history-list'>
          {HISTORY_BRAND_DATA_2.map((item, index) => (
            <HistoryItem key={index} {...item} />
          ))}
        </div>
      
      </div>
    </div>
  );
};