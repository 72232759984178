import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "./style.css";
import FeatureProgram from "../../FeatureProgram/FeatureProgram";
import PromotionPartnership from "../../PromotionPartnership/PromotionPartnership";
import Benefit from "../../Benefit/Benefit";
import {PAGE, RANK} from "../../../constants/appConst";
import {Link, useNavigate} from "react-router-dom";
import UserInfoPopup from "../../Popup/UserInfoPopup/UserInfoPopup";
import {formatNumberWithDot} from "../../../utils/utils";

export const HomeContent = ({userData, phoneNumber}) => {
	const [userInfoPopup, setUserInfoPopup] = useState(false);
	const [masked, setMasked] = useState(true);
	const navigate = useNavigate();
	const historyRef = useRef(null);
	
	const {userFirstOrderDate, userLifetime, totalSpending, businessUnit, loyaltyStar, loyaltyRank} = userData || {};
	
	useLayoutEffect(() => {
		const maskedLocal = localStorage.getItem('masked');
		console.log(!!maskedLocal)
		setMasked(!!maskedLocal);
	}, []);
	
	const openUserPopup = () => {
		
		if (!userData) {
			setUserInfoPopup(true);
		} else {
			navigate(PAGE.COMPANION);
		}
	}
	
	const onClickOkMask = () => {
		localStorage.setItem('masked', 'true');
		setMasked(true)
	}
	
	const onClosed = () => {
		setUserInfoPopup(false)
	}
	
	const rankData = RANK[loyaltyRank] || RANK.BRONZE;
	
	return (
		<>
			
			<div className="home-content animate-fadeIn">
				{
					!masked && (
						<div className="mask-init">
							<div className='mask-init-wp'>
								<div className='mask-init-bubble'>
									Chào mừng bạn là thành viên mới của Chương trình Khách hàng Thân thiết FUTA Group. Hãy khám phá hành
									trình
									tích lũy điểm của bạn tại đây
								</div>
								<button className='mask-init-button' onClick={onClickOkMask}>Đã hiểu</button>
								<div className='round-arrow'>
									<img className="" src="/img/round-arrow.png" alt='round-arrow'/>
								</div>
								<div className="tich-diem-lich-su">
									<img className='point-banner' src='/img/point_banner.png' alt='point_banner'/>
									<div className="banner">
										<p className="text-wrapper-9">Tích điểm lịch sử tiêu dùng từ FUTA Group</p>
										<p className="kh-m-ph-h-nh-tr-nh">
											Khám phá hành trình tiêu dùng của bạn tại<br/>FUTA Group và tích điểm ngay!
										</p>
										<div className="btn-arrow">
											<div className="frame-5">
												<div className="text-wrapper-10">Khám phá ngay</div>
												<img className="group-4" src="/img/group.png"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						
						</div>
					)
				}
				
				<div className='content-wrapper'>
					<div className="frame">
						<div className="overlap">
							<div className="card">
								<div className='card-info'>
									<div className='card-left'>
										<div className="text-wrapper-7">{phoneNumber}</div>
										<div className='rank-qr'>
											<Link to={PAGE.RANKING}>
												<img className="text-rank" src={rankData.image2} alt='text-rank'/>
												
												{/*<button className="btn">
                        <div className="text-wrapper-6">{rankData.memberRank || RANK.BRONZE.memberRank}</div>
                        <img className="group-3" src="/img/group-2.png" alt='group-3'/>
                      </button>*/}
											</Link>
											<Link to={PAGE.QRCODE}>
												<div className="QR">
													<div className="text-wrapper-8">QR</div>
													<img className="union" src="/img/union.png"/>
												</div>
											</Link>
										</div>
										<div className="overlap-3">
											<div className="group-2">
												<div className='flex justify-center w-[215px] gap-1 align-middle'>
													<div className="text-wrapper-4">{formatNumberWithDot(loyaltyStar || 0)} điểm</div>
													<div className="marquee-container flex-1">
														<div className="marquee text-white text-[12px]">
															điểm của bạn sẽ hết hạn vào 31/12/2024
														</div>
													</div>
												</div>
												<div className="rectangle-wrapper">
													<div style={!loyaltyStar ? {width: 0} : null} className="rectangle"></div>
												</div>
											</div>
										</div>
									</div>
									<Link to={PAGE.RANKING}>
										<div className='card-right'>
											<div className="overlap-4">
												<img className="mem-level" src={rankData?.image1} alt='mem-level'/>
												<img className="mask-group-2" src="/img/mask-group-1.png" alt='mask-group-2'/>
											</div>
										</div>
									</Link>
								</div>
								<div className="frame-2">
									<p
										className="p">{rankData.code === RANK.DIAMOND.code ? "Chúc mừng  bạn đã đạt hạng thành viên cao nhất!" : "Chỉ cần cố gắng chút nữa, bạn sẽ thăng hạng!"}</p>
								</div>
							</div>
							<Benefit useData={userData} rankData={rankData}/>
						</div>
					</div>
					<div className="tich-diem-lich-su" ref={historyRef}>
						<img className='point-banner' src='/img/point_banner.png' alt='point_banner'/>
						<div className="banner" onClick={openUserPopup}>
							<p className="text-wrapper-9">Tích điểm lịch sử tiêu dùng từ FUTA Group</p>
							<p className="kh-m-ph-h-nh-tr-nh">
								Khám phá hành trình tiêu dùng của bạn tại<br/>FUTA Group và tích điểm ngay!
							</p>
							<div className="btn-arrow">
								<div className="frame-5">
									<div className="text-wrapper-10">Khám phá ngay</div>
									<img className="group-4" src="/img/group.png"/>
								</div>
							</div>
						</div>
					</div>
					<FeatureProgram/>
					<PromotionPartnership/>
				</div>
				{userInfoPopup && <UserInfoPopup onClosed={onClosed}/>}
			</div>
		</>
	);
};