import React, {useEffect, useRef} from "react";
import "./style.css";
import {Link, useLocation} from "react-router-dom";
import {PAGE} from "../../constants/appConst";

export const BottomNav = () => {
  
  const bottomRef = useRef(null)
  const location = useLocation()
  
  const {pathname} = location || {};

  return (
    <div ref={bottomRef} className="bottom-nav">
      <Link to={PAGE.HOME} className="nav-item">
        <div className={`nav-item${pathname === PAGE.HOME ? ' bg-active': ''}`}>
          <img className="icon-bot-nav" src={pathname === PAGE.HOME ? "/img/home-two-1.svg" : "/img/home.png"} alt='home'/>
          <div className="label">Trang chủ</div>
        </div>
      </Link>
      <Link to={PAGE.HISTORY} className="nav-item">
        <div className={`nav-item${pathname === PAGE.HISTORY ? ' bg-active' : ''}`}>
          <img className="icon-bot-nav" src={pathname === PAGE.HISTORY ? "/img/watch_active.png" : "/img/group-1.png"} alt='history'/>
          <div className="label">Lịch sử</div>
        </div>
      </Link>
      <Link to={PAGE.RANKING} className="nav-item">
        <div className={`nav-item${pathname === PAGE.RANKING ? ' bg-active' : ''}`}>
          <img className="icon-bot-nav" src={pathname === PAGE.RANKING ? "/img/ranking_active.png" : "/img/group-4.png"} alt='level'/>
          <div className="label">Hạng</div>
        </div>
      </Link>
    
    </div>
  );
};
