import React from "react";
import "./style.css";
import {Header} from "../../components/Header";
import {HistoryContent} from "../../components/Content/HistoryContent";
import {BottomNav} from "../../components/BottomNav";

export const History = () => {
  
  return (
    <div className="gp-screen">
      <Header headClassname='head-bg'/>
      <HistoryContent />
      <BottomNav />
    </div>
  );
};
