

export const PAGE = {
	LOGIN: '/',
	HOME: '/home',
	HISTORY: '/history',
	HISTORY_BRAND: '/history-brand',
	RANKING: '/ranking',
	COMPANION: '/companion',
	QRCODE: '/qr-code',
}

export const EL_ID = {
	COMPANION_WRAPPER: 'COMPANION_WRAPPER',
}

export const RANK = {
	BRONZE: {
		code: "'BRONZE'",
		name: "Đồng",
		memberRank: "Thành viên Đồng",
		image1: '/img/card-ani/card-bronze-ani.gif',
		image2: '/img/card-ani/btn-bronze.png',
		benefitCount: 1,
		benefitTitle: "Quyền lợi thành viên Đồng",
		progress: 0
	},
	SILVER: {
		code: "'SILVER'",
		name: "Bạc",
		memberRank: "Thành viên Bạc",
		image1: '/img/card-ani/card-silver-ani.gif',
		image2: '/img/card-ani/btn-silver.png',
		benefitCount: 2,
		benefitTitle: "Quyền lợi thành viên Bạc",
		progress: 33
	},
	GOLD: {
		code: "'GOLD'",
		name: "Vàng",
		memberRank: "Thành viên Vàng",
		image1: '/img/card-ani/card-gold-ani.gif',
		image2: '/img/card-ani/btn-gold.png',
		benefitCount: 3,
		benefitTitle: "Quyền lợi thành viên Vàng",
		progress: 66
	},
	DIAMOND: {
		code: "'DIAMOND'",
		name: "Kim cương",
		memberRank: "Thành viên Kim Cương",
		image1: '/img/card-ani/card-diamond-ani.gif',
		image2: '/img/card-ani/btn-diamond.png',
		benefitCount: 3,
		benefitTitle: "Quyền lợi thành viên Kim Cương",
		progress: 100
	}
}