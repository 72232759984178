import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./screens/Home";
import {History} from "./screens/History";
import {Ranking} from "./screens/Ranking";
import {HistoryBrand} from "./screens/HistoryBrand";
import {Companion} from "./screens/Companion";
import {PAGE} from "./constants/appConst";
import {Login} from "./screens/Login";
import {QrCode} from "./screens/QrCode";

const router = createBrowserRouter([
  {
    path: PAGE.LOGIN,
    element: <Login />,
  },
  {
    path: PAGE.HOME,
    element: <Home />,
  },
  {
    path: PAGE.HISTORY,
    element: <History />,
  },
  {
    path: PAGE.RANKING,
    element: <Ranking />,
  },
  {
    path: PAGE.HISTORY_BRAND,
    element: <HistoryBrand />,
  },{
    path: PAGE.COMPANION,
    element: <Companion />,
  },{
    path: PAGE.QRCODE,
    element: <QrCode />,
  }
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
