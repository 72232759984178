import React, {useState} from "react";
import Popup from "../index";
import "./style.css";
import {fetchData} from "../../../api/api";
import {useNavigate} from "react-router-dom";
import {PAGE} from "../../../constants/appConst";
import Toast from "../../Toast/Toast";

const UserInfoPopup = ({onClosed}) => {
	const navigate = useNavigate();
	const [toastMessage, setToastMessage] = useState("")
	const phoneNumber = localStorage.getItem('phoneNumber');
	
	
	const gotoCompanion = async () => {
		if (phoneNumber) {
			try {
				const userData = await fetchData({phoneNumber});
				localStorage.setItem('userData', JSON.stringify(userData));
				if(userData?.data || (userData?.code === 2 || userData?.code === 3)) {
					navigate(PAGE.COMPANION);
				} else {
					setToastMessage("Hệ thống đang xử lý, vui lòng thử lại sau")
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
				
				setToastMessage("Hệ thống đang xử lý, vui lòng thử lại sau")
			}
		}
	};
	
	return (
		<Popup>
			<div className="popup animate-fadeIn">
				<div className="overlap-group">
					<img className="popupbg" src="/img/popup/popupbg-1.png" alt='image'/>
					<p className="text-wrapper">Sử dụng dữ liệu người dùng</p>
					<img className="image" src="/img/popup/image-17.png" alt='image'/>
					<p className="div">
						Bằng việc nhấn “Đồng ý” đồng nghĩa với việc bạn chấp nhận FUTA chia sẻ dữ liệu cá nhân của bạn trong việc
						tích điểm trên dịch vụ
					</p>
					<img className="group" src="/img/popup/group.png" alt='group'/>
					<div className="rectangle"></div>
					<div className="rectangle-2"></div>
					<button className="btn" onClick={gotoCompanion}>
						<div className="text">Đồng ý</div>
					</button>
				</div>
				<div className="btn-outline" onClick={onClosed}>
					<div className="text-2">Từ chối</div>
				</div>
			</div>
			{toastMessage && <Toast message={toastMessage} onClose={() => setToastMessage('')} />}
		</Popup>
	);
};

export default UserInfoPopup;