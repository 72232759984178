import axios from 'axios';

export async function fetchData({phoneNumber}) {
	try {
		const response = await axios.get(`https://dev-adhoc.goopay.vn/api/v1/futa-demo/${phoneNumber}`);
		return response?.data || {}
	} catch (error) {
		console.error('Error fetching data:', error);
		return error
	}
}
